<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Assign Asset</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-layout wrap justify-start>
          <v-flex xs12 pa-4>
            <v-spacer></v-spacer>
          </v-flex>

          <v-flex xs12 align-self-center fill-height pa-0>
            <v-card tile flat>
              <v-form v-model="addcat" ref="addcat">
                <v-layout wrap>
                  <v-flex xs12 sm4 align-self-center text-left pa-2>
                    <v-select
                      v-model="assetId"
                      :items="assetList"
                      item-text="name"
                      item-value="_id"
                      :rules="[rules.required]"
                      hide-details
                      outlined 
                      dense
                      label="Choose asset"
                    >
                    </v-select>
                  </v-flex>

                  <v-flex xs12 sm8 align-self-center text-left pa-2>
                    <!-- <v-autocomplete
                      v-model="inventory"
                      :items="inventoryList"
                      :item-text="
                        (item) => item.assetId.name + ' - ' + item.assetTag
                      "
                      :search-input.sync="searchInv"
                      item-value="_id"
                      :rules="[rules.required]"
                      required
                      outlined
                      hide-details
                      label="Choose Items"
                      dense  
                    >
                    </v-autocomplete> -->

                    <v-autocomplete
                      v-model="inventory"
                      :items="inventoryList"
                      item-text="code"
                      :search-input.sync="searchInv"
                      item-value="_id"
                      :rules="[rules.required]"
                      required
                      outlined
                      hide-details
                      label="Choose Asset Item"
                      dense
                      clearable 
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 align-self-center pa-2>
                    <v-card flat style="border:2px dotted #000" tile class="kumbhRegular">
                      <v-layout wrap justify-start>
                        <v-flex xs12 align-self-center text-left pa-2>
                          <span> Selected Item(s) </span>
                        </v-flex>
                        <v-flex
                          pa-2
                          align-self-center
                          text-center
                          v-for="(selected, s) in sel"
                          :key="s"
                        >
                          <v-chip
                            class="ma-2"
                            close
                            close-icon="mdi-close-outline"
                            color="#000"
                            text-color="white"
                            @click:close="sel.splice(s, 1)"
                          >
                            <span v-if="selected.length > 0">{{
                              selected[0].code
                            }}</span>
                          </v-chip>
                          <!-- <v-card class="rounded-0" dark color="#000">
                           
                            <v-icon
                              @click.prevent="sel.splice(s, 1)"
                              title="Remove item"
                              color="error"
                              >mdi-close</v-icon
                            >
                          </v-card> -->
                        </v-flex>
                        <!-- <v-flex>
                          <v-spacer></v-spacer>
                        </v-flex> -->
                        <v-flex
                          xs12
                          pa-4
                          align-self-center
                          text-left
                          v-if="!sel.length > 0"
                        >
                          <span class="kumbhRegular">No Items selected!</span>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>

                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-select
                      v-model="assignType"
                      :items="assignList"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      label="Choose assign type"
                    >
                    </v-select>
                  </v-flex>

                  <v-flex
                    xs12
                    sm6
                    align-self-center
                    text-left
                    pa-2
                    v-if="
                      assignType == 'Department' || assignType == 'Employee'
                    "
                  >
                    <v-select
                      v-model="departmentId"
                      :items="deptList"
                      item-text="deptName"
                      item-value="_id"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      @change="employeeId = null"
                      label="Choose department"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex
                      xs12
                      sm6
                      align-self-center
                      text-left
                      pa-2
                      v-if="departmentId"
                    >
                      <v-select
                        v-model="divisionId"
                        :items="divisionlist"
                        item-text="divisiontName"
                        item-value="_id"
                        :rules="[rules.required]"
                        hide-details
                        outlined
                        dense
                        @change="employeeId = null"
                        label="Choose division"
                      >
                      </v-select>
                    </v-flex>

                  <v-flex
                    xs12
                    sm6
                    align-self-center
                    text-left
                    pa-2
                    v-if="assignType == 'Employee' && departmentId"
                  >
                    <!-- <v-select
                      v-model="employeeId"
                      :items="empList"
                      item-text="name"
                      item-value="_id"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      label="Choose employee"
                    >
                    </v-select> -->
                    <v-autocomplete
                      v-model="employeeId"
                      :items="empList"
                      item-text="name"
                      :search-input.sync="search1"
                      item-value="_id"
                      :rules="[rules.required]"
                      required
                      outlined
                      hide-details
                      label="Choose employee"
                      dense
                      clearable
                    >
                    </v-autocomplete>
                  </v-flex>


                  <v-flex
                    xs12
                    sm6
                    align-self-center
                    text-left
                    pa-2
                    v-if="departmentId
                    "
                  >
                    <v-select
                      v-model="projectId"
                      :items="projectList"
                      item-text="projectName"
                      item-value="_id" 
                      hide-details
                      outlined
                      dense 
                      label="Choose project"
                    >
                    </v-select>
                  </v-flex>

                  <!-- <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="quantity"
                      label="Quantity"
                      :rules="[rules.required]"
                      required
                      hide-details
                      outlined
                      type="number"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex> -->
                  <v-flex xs12  align-self-center pa-2>
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm6 md4 align-self-center px-4>
                        <v-btn
                          color="#005f32"
                          dark
                          tile
                          large
                          class="rounded-xl"
                          block
                          @click="validateInput()"
                        >
                          Submit
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>

        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,

      addcat: false,
      addcat2: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      radios: null,
      divisionlist: [],
      categoryList: [],
      assetCategoryId: null,
      name: "",
      assetType: null,
      typeList: ["Consumable", "Nonconsumable","Semiconsumable"],
      assignList: ["Department", "Employee"],
      description: null,
      unitPrice: null,
      assignType: null,
      employeeId: null,
      departmentId: null,
      divisionId:null,
      empList: [],
      deptList: [],
      assetList: [],
      quantity: null,
      assetId: null,
      search1: null,
      inventoryList: null,
      searchInv: null,
      inventory: null,
      selectedAssets: [],
      sel: [],
      selAr: [],
      inventoryStockIds: [],
      stayalive: false,
      projectList:[],
      projectId:null,
    };
  },
  watch: {
    radios() {
      console.log("Rad=", this.radios);
      this.clearOff();
    },
  
    assignType() {
      // if(this.assignType == 'Department') {
      this.employeeId = null;
      this.departmentId = null;
      this.projectId=null
      // }
    },
    divisionId(){
      this.getProject()
      this.getEmp()
      // this.getlist();

    },
    departmentId() {
      // this.selAr = [];
      // this.sel = [];
      // this.inventory=null
      this.projectId=null
      this.getDivision();
      // this.getInventory();
      // this.getEmp();
    },
    employeeId() {
      // this.getProject()
      // this.selAr = [];
      // this.sel = [];
      // this.inventory=null
      this.getInventory();
      this.getProject()
    },
    search1() {
      this.getEmp();
    },
    assetId() {
      this.inventory=null
      this.getInventory();
    },
    inventory() {
      console.log("List=", this.inventory);
      if (!this.selAr.includes(this.inventory) && this.inventory)
        this.selAr.push(this.inventory);
      else return;

      this.sel.push(this.inventoryList.filter((x) => x._id == this.inventory));

      console.log("Full=", this.sel);
    },
    searchInv() {
      this.currentPage = 1;
      console.log("Cl", this.searchInv);
      if(this.searchInv)
      this.getInventory();
    },

    // currentPage() {
    //   this.getInventory();
    // },
  },
  beforeMount() {
    // this.getlist();
    this.getDept();
    // this.getEmp();
    this.getAssets();
  },

  methods: {
    getDivision() {
      // this.appLoading = true;
      axios({
        url: "/division/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
        departmentId: this.departmentId,
        },
      })
        .then((response) => {
          this.divisionlist = response.data.data;
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    searchIt() {
        this.currentPage = 1;
      console.log("Cl", this.searchInv);
      this.getInventory();
    },

     getProject() {
      // this.appLoading = true;
      axios({
        url: "/projects/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          // departmentId: this.departmentId,
          employeeId: this.employeeId,
          divisionId: this.divisionId,
        },
      })
        .then((response) => {
          // this.appLoading = false;
          this.projectList = response.data.data;
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    // callSearch() {

    //   console.log("Cll s",this.sel)
    // },
    // insertItem() {
    //   console.log("List inv=", this.inventory);
    //   if (!this.selAr.includes(this.inventory)) this.selAr.push(this.inventory);
    //   else return;

    //   this.sel.push(this.inventoryList.filter((x) => x._id == this.inventory));

    //   console.log("Full=", this.sel);
    // },
    getInventory() {
      // this.appLoading = true;
      // this.inventoryList = null;
      axios({
        url: "/asset/inventoryStocks/list",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          assetId: this.assetId,
          assignType: "Not Assigned",
          keyword: this.searchInv,
          page: 1,
          limit: 100,
        },
      })
        .then((response) => {
          // this.appLoading = false;
          this.inventoryList = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getAssets() {
      // this.appLoading = true;
      axios({
        url: "/assets/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.appLoading = false;
          this.assetList = response.data.data;
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getDept() {
      // this.appLoading = true;
      axios({
        url: "/department/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.appLoading = false;
          this.deptList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getlist() {
        var data = {};
        data["departmentId"] = this.departmentId;
        axios({
          url: "/division/list",
          method: "GET",
          data: data,
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.divisionlist = response.data.data;
        
          })
          .catch((err) => {
         
            this.ServerError = true;
            console.log(err);
          });
      },
    getEmp() {
      // this.appLoading = true;
      axios({
        url: "/employees/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          divisionId: this.divisionId,
          keyword: this.search1,
        },
      })
        .then((response) => {
          // this.appLoading = false;
          this.empList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    validateInput() {
      // if (!this.assetId) {
      //   this.msg = "Please choose an asset";
      //   this.showsnackbar = true;
      //   return;
      // } else if (!this.quantity) {
      //   this.msg = "Please provide quantity";
      //   this.showsnackbar = true;
      //   return;
      // }
      if (this.sel.length > 0) {
        this.inventoryStockIds = this.sel.map((x) => x[0]._id);
      }
      if (!this.sel.length > 0) {
        this.msg = "Please choose atleast one asset";
        this.showsnackbar = true;
        return;
      } else if (!this.assignType) {
        this.msg = "Please provide assign type";
        this.showsnackbar = true;
        return;
      } else if (!this.departmentId) {
        this.msg = "Please choose a department";
        this.showsnackbar = true;
        return;
      } else if (this.assignType == "Employee" && !this.employeeId) {
        this.msg = "Please choose an employee";
        this.showsnackbar = true;
        return;
      } else {
        this.addAsset();
      }
    },
    addAsset() {
      // var data = {};
      // data["name"] = this.name;
      axios({
        url: "/asset/assign",
        method: "POST",
        data: {
          // assetId: this.assetId,
          projectId: this.projectId,
          departmentId: this.departmentId,
          employeeId: this.employeeId,
          assignType: this.assignType,
        divisionId: this.divisionId,
          // quantity: this.quantity,
          inventoryStockIds: this.inventoryStockIds,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.name = null;
            this.dialog = false;

            this.$router.push("/Assets/Inventory");
            this.clearOff();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    clearOff() {
      this.employeeId = null;
      this.assignType = null;
      this.quantity = null;
      this.departmentId = null;
      this.assetId = null;
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/assetCategory/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
